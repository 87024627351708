import { gsap } from 'gsap';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);

const animations = {
	initLoad() {
		this.initUcAnimations();
		if(!$('.visual-experience').length) this.initHeaderAnim()
	},

	initHeaderAnim(){
		const logo = document.querySelector('.header__logo')
		const menu = document.querySelectorAll('.header__menu')

		gsap.timeline()
		.addLabel('start')
		.to(logo, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(menu, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
	},

	initExpEffect() {
		const $el = $('.visual-experience');
		if($el.length == 0) return false

		const logo = document.querySelector('.header__logo')
		const title = document.querySelectorAll('.visual-experience__content__title .word')
		const brush = document.querySelector('.visual-experience__content__brush path')
		const menu = document.querySelector('.header__menu')
		const navigation = document.querySelector('.visual-experience__navigation')
		const circles = document.querySelectorAll('.circles-small .circle')
		const scrollHint = document.querySelector('.visual-experience__scroll-hint')

		gsap.timeline()
		.addLabel('start')
		.to(logo, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(menu, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(scrollHint, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(navigation, {x: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(circles, {opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(title, {y: 0, opacity: 1, duration: 1, stagger: { from: "start", each: 0.2, ease: 'Power2.Out' }, delay: .6 }, 'start')
		.addLabel('middle', "-=1")
		.fromTo(brush, {drawSVG:"0%"}, {duration: 1, drawSVG:"100%", ease:"power3.inOut"}, 'middle')
	},

	initUcAnimations() {
		const $el = $('.uc__content__title .char');
		if($el.length == 0) return false

		const logo = document.querySelector('.header__logo')
		const title = document.querySelectorAll('.uc__content__title .char')
		const socials = document.querySelectorAll('.uc__social li')
		const brush = document.querySelectorAll('.uc__content__brush')
		const text = document.querySelectorAll('.uc__content__text')

		gsap.timeline( {
			defaults: {duration: .4, ease: 'power4.Out', delay: .5},
			onStart: () => {},
			onComplete: () => {
			}
		})
		.addLabel('start')
		.to(logo, {y: 0, opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(title, {transform: "scaleX(1)", opacity: 1, duration: 1, stagger: { from: "center", each: 0.04, ease: 'Power2.Out' } }, 'start')
		.to(socials, {y: 1, opacity: 1, duration: .8, stagger: { each: 0.12, ease: 'Power2.Out' } }, 'start')
		.addLabel('middle', "-=1")
		.to(brush, {y: 1, opacity: 1, duration: .8, ease: 'Power3.inOut' }, 'middle')
		.addLabel('end', "-=.6")
		.to(text, {opacity: 1, duration: .8, ease: 'Power3.inOut' }, 'end')
	},
}

export default animations;
