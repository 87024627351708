import Splitting from "splitting";

const splitting = {
	initLoad() {
		splitting.initSplitting();
	},

	initSplitting() {
		Splitting();
	},

	splitInit(lines, el) {
		[...lines].forEach((line, index) => {
			let wrapper = document.createElement("div");
			wrapper.classList.add("line", `line--${index}`);
			[...line].forEach((cont) => {
				let space = document.createElement("span");
				space.innerText += " ";
				space.classList.add("whitespace");

				wrapper.appendChild(cont).after(space);
			});
			el.appendChild(wrapper);
		});
  	},
  splitText(el) {
    if ($(el).length) {
		const splitEl = Splitting({ target: el, by: "lines", whitespace: true });
		el.innerHTML = "";
		splitting.splitInit(splitEl[0].lines, el);
    }
  },
}

export default splitting;
