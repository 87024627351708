const video = {
	initLoad() {
		this.initArteVideo();
		this.initVideo();
	},
	initArteVideo() {
		const $el = $('.common--video');
		if ($el.length) {
			const srcD = $el.data('src');
			$el.find('source').attr('src', srcD);

			$el.attr('preload', 'auto')
			$el[0].load()
			$el[0].play()
		}
	},
	initVideo() {
		const $el = $('#video');
		if ($el.length) {
			const srcD = $el.data('src');
			const srcM = $el.data('src-mobile');
			if ($(window).width() <= 768) {
				$el.find('source').attr('src', srcM);
			} else {
				$el.find('source').attr('src', srcD);
			}
			$el.attr('preload', 'auto')
			$el[0].load()
		}
	},
}

export default video;
