import gsap from "gsap";

const contacts = {

  initLoad(){

    contacts.removeStickyMobile()
  },
  removeStickyMobile(){

		if ( $('#contacts').length == 0 ) return

		const $contactsImageContent = $('#contacts .image-content');
		const $contactsWrapper = $contactsImageContent.find('.padding-wrapper');
		const $contactsImage = $contactsImageContent.find('img');

		const $contactsInfos = $('#contacts .info-content');
		const $contactsInfosTitle = $contactsInfos.find('.page-title');
		const $contactsInfosTexts = $contactsInfos.find('.texts-wrapper');

		const tl = gsap.timeline()
					.addLabel('start')
					tl.to($contactsWrapper,{ "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: 2.5, ease: "power4.out" }, 'start+=2')
					tl.to($contactsImage,{ scale: 1, duration: 2.5, ease: "power4.out" }, 'start+=2')
					.addLabel('middle')
					tl.fromTo($contactsInfosTitle,{yPercent: 100}, { yPercent: 0, duration: 2.5, ease: "power4.out" }, 'start+=2.5')
					.addLabel('end')
					tl.fromTo($contactsInfosTexts, {opacity: 0},{opacity: 1,duration: 1.5,stagger: { from: "start", each: 0.1, ease: 'Power2.inOut' } }, 'start+=3')

    if ($(window).outerWidth() < 767){

			// remove sticky behavior
			$contactsImageContent.removeAttr('data-scroll')
			$contactsImageContent.removeAttr('data-scroll-offset')
			$contactsImageContent.removeAttr('data-scroll-sticky')
			$contactsImageContent.removeAttr('data-scroll-target')
		}

  }
}

export default contacts
