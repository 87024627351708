import gsap from "gsap";

const products = {
	windowHalfX: window.innerWidth,
	windowHalfY: window.innerHeight,
	mouseX: 0,
	mouseY: 0,
	currentImg: '',

	initLoad() {
		const $el = $('.products-cat')
		if($el.length){
			document.addEventListener( 'mousemove', products.imageFollow );
			this.productsListAnimations();
		}

		products.singleProductAnim();
	},

  	productsListAnimations() {
  		const $el = $('.products-cat');
		if($el.length == 0) return false

  		$(document).on('mouseenter', '.products-cat__row', function(){
			const $prodLine = $(this).find('.products-cat__row__wrap--clipped')
			const $prodImgWrap = $(this).find('.products-cat__img')
			const $prodImg = $(this).find('img')
			products.currentImg = $prodImgWrap

			gsap.killTweensOf($prodLine)
			gsap.killTweensOf($prodImgWrap)
			gsap.killTweensOf($prodImg)

  			products.loopRender();
  			$(this).addClass('high');

		 	gsap.timeline()
		 		.addLabel('start')
		 		.to($prodLine, {"clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: 1.2, ease: "Power3.in" }, 'start')
		 		.to($prodImgWrap, {opacity: 1, duration: .3, ease: "Expo.out"}, 'start')
		 		.to($prodImgWrap, {"clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: .8, ease: "Expo.out", delay: .1 }, 'start')
		 		.to($prodImg, {scale: 1.2, duration: 2, ease: "power1.out"}, 'start')
		})

		$(document).on('mouseleave', '.products-cat__row', function(){
			const $prodLine = $(this).find('.products-cat__row__wrap--clipped')
			const $prodImgWrap = $(this).find('.products-cat__img')
			const $prodImg = $(this).find('img')
			products.currentImg = $prodImgWrap

			gsap.killTweensOf($prodLine)
			gsap.killTweensOf($prodImgWrap)
			gsap.killTweensOf($prodImg)

			products.stopRendering();
			$(this).removeClass('high');

		 	gsap.timeline()
		 		.addLabel('start')
		 		.to($prodLine, {"clip-path": "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", duration: .6, ease: "Power3.out" }, 'start')
				.to($prodImg, {scale: 1.3, duration: .3, ease: "Expo.easeOut" }, 'start')
		 		.to($prodImgWrap, {opacity: 0, "clip-path": "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", duration: .3, ease: "Expo.easeOut" }, 'start')
		})

		$(document).on('mousemove', '.products-cat__row', function(){
			const $container = $(this);
			const $prodImgWrap = $(this).find('.products-cat__img')
			const $prodImg = $(this).find('img')

			products.parallax($container, $prodImg, -125)
		})
  	},

  	imageFollow(event) {
  		products.mouseX = ( event.clientX );
		products.mouseY = ( event.clientY );
  	},

  	loopRender() {
        if ( !products.requestId ) {
            products.requestId = requestAnimationFrame(() => products.render());
        }
    },

    stopRendering() {
        if ( products.requestId ) {
            window.cancelAnimationFrame(products.requestId);
            products.requestId = undefined;
        }
    },

    render() {
    	products.requestId = undefined;
    	const parentOffset = products.currentImg.parent().offset();

        gsap.to(products.currentImg, {
            left: Math.abs(products.mouseX - parentOffset.left + 1),
            top: Math.abs(products.mouseY - parentOffset.top),
            duration: 1,
            ease: "Power4.easeOut"
        });

        products.loopRender();
    },

    parallax(container, target, movement) {
		var $container = container;
		var relX = products.mouseX - $container.offset().left;
		var relY = products.mouseY - $container.offset().top;

		gsap.to(target, {
			x: ((relX - $container.width() / 2) / $container.width() * movement) * -1,
			y: ((relY - $container.height() / 2) / $container.height() * movement) * -1,
			duration: 1,
			ease: "Power4.easeOut"
		});
	},

	singleProductAnim(){

		if ($('#single-product').length == 0) return

		if ($(window).outerWidth() < 767){

			// remove sticky behavior
			const $productImage = $('#product-wrapper .image-content');
						$productImage.removeAttr('data-scroll')
						$productImage.removeAttr('data-scroll-offset')
						$productImage.removeAttr('data-scroll-sticky')
						$productImage.removeAttr('data-scroll-target')
		}

		const $imageContent = $('#single-product').find('.image-content .js-content');
		const $image = $('#single-product').find('.image-content img');
		const $productFamily = $('#single-product').find('.product-family .word')
		const $productName = $('#single-product').find('.product-name .word')
		const $productSubtitle = $('#single-product').find('.product-subtitle .word')
		const $productInfo = $('#single-product').find('.product-info')

		const tl = gsap.timeline()
					.addLabel('start')
					tl.fromTo($imageContent, {scaleX: 0}, {scaleX: 1, duration: 2.5, ease: "expo.out" },"start+=0.75")
					.addLabel('middle')
					tl.fromTo($image, {opacity:0, scale: 1,xPercent: -50}, {opacity:1,scale: 1.2, duration: 1.8, ease: "Power2.easeOut" }, "middle-=2")
					.addLabel('end')
					tl.fromTo($productFamily, {yPercent: 100},{yPercent: 0,duration: 1.5,stagger: { from: "start", each: 0.2, ease: 'Power2.inOut' } }, 'end-=1.9')
					tl.fromTo($productName, {yPercent: 100},{yPercent: 0,duration: 1.5,stagger: { from: "start", each: 0.2, ease: 'Power2.inOut' } }, 'end-=1.5')
					//tl.fromTo($productSubtitle, {yPercent: 100},{yPercent: 0,duration: 1.5,stagger: { from: "start", each: 0.2, ease: 'Power2.inOut' } }, 'end-=1')
					tl.fromTo($productInfo, {opacity: 0},{opacity: 1,duration: 1.5,stagger: { from: "start", each: 0.1, ease: 'Power2.inOut' } }, 'end-=1')
	}



}

export default products;
