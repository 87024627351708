import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import {positionArray} from "./positionArray"

import * as dat from 'dat.gui';

import gsap from "gsap";

import preloader from './preloader';
import animations from './animations';

let container, camera, scene, renderer, model, gui, windowHalfX, windowHalfY, mouseX, mouseY, totalDots, isAnim, requestAnimId, stats;

const exp = {
	container,
	camera,
	scene,
	renderer,
	model,
	gui,
	clock: new THREE.Clock(),
	windowHalfX: window.innerWidth / 2,
	windowHalfY: window.innerHeight / 2,
	mouseX: 0,
	mouseY: 0,
	totalDots: 0,
	isAnim: 0,
	requestAnimId,
	stats,

	initReady() {


		if ( $('#visual-experience').length == 0) return

		exp.buildNavigation();
		exp.init()
		exp.tick()
		exp.initCircleText()
	},

	initDebug() {
		exp.stats = new Stats();
		exp.stats.domElement.style.position = 'absolute';
		exp.stats.domElement.style.bottom = '0px';
		exp.stats.domElement.style.zIndex = 100;
		exp.container.appendChild( exp.stats.domElement );

		exp.gui = new dat.GUI({ width: 300 })
		exp.gui
		    .add(exp.model.rotation, 'y')
		    .min(-3)
		    .max(3)
		    .step(0.01)
		    .name('HDR rotation Y')
		    .listen()
		exp.gui
		    .add(exp.camera.rotation, 'x')
		    .min(-1)
		    .max(1)
		    .step(0.01)
		    .name('Camera rotation X')
		    .listen()
		exp.gui
		    .add(exp.camera.rotation, 'y')
		    .min(-1)
		    .max(1)
		    .step(0.01)
		    .name('Camera rotation Y')
		    .listen()
		exp.gui
		    .add(exp.camera.rotation, 'z')
		    .min(-1)
		    .max(1)
		    .step(0.01)
		    .name('Camera rotation Z')
		    .listen()
		exp.gui
		    .add(exp.camera.position, 'x')
		    .min(-100)
		    .max(100)
		    .step(0.01)
		    .name('Camera position X')
		    .listen()
		 exp.gui
		    .add(exp.camera.position, 'y')
		    .min(-100)
		    .max(100)
		    .step(0.01)
		    .name('Camera position Y')
		    .listen()
		exp.gui
		    .add(exp.camera.position, 'z')
		    .min(0)
		    .max(1000)
		    .step(0.01)
		    .name('Camera position Z')
		    .listen()
		exp.gui
		    .add(exp.renderer, 'toneMappingExposure')
		    .min(-10)
		    .max(10)
		    .step(0.01)
		    .name('Esposizione')
		    .listen()
	},

	init() {
		exp.container = document.querySelector('.webgl')

		exp.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 10, 1000 );
		exp.camera.setFocalLength = 135;
		exp.camera.position.set( 0, 0, 90);

		exp.scene = new THREE.Scene();

		new RGBELoader()
			.setDataType( THREE.UnsignedByteType )
			.load( '/three/uva/studio-new-3.hdr', function ( texture ) {

				preloader.update(.5);

				const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
				// exp.scene.background = envMap;
				exp.scene.environment = envMap;

				texture.dispose();

				pmremGenerator.dispose();

				// model
				// const roughnessMipmapper = new RoughnessMipmapper( exp.renderer );

				const loader = new GLTFLoader();
				let modelUrl = '';
				if($('.safari').length) modelUrl = '/three/uva/grappolo-low.gltf'
				else modelUrl = '/three/uva/grappolo-new.gltf'
				loader.load( modelUrl, function ( gltf ) {

					exp.model = gltf.scene;

					exp.scene.add( gltf.scene );

					// roughnessMipmapper.dispose();

					exp.resize();

					preloader.update(1, true);

					setTimeout(function(){
						$('#main').removeClass('is-exiting');

						// Intro del grappolo
						gsap.timeline()
							.addLabel('start')
							.to(exp.camera.position, {
					    		x: 0,
					    		y: 0,
					    		z: 95,
					    		duration: 2,
					    		ease: "Power3.Out"
				    		}, 'start')
						// intro effetti
						animations.initExpEffect()
					}, 350);


					if(window.location.hash.substr(1) == 'debug') exp.initDebug();

				} );

			});

		exp.renderer = new THREE.WebGLRenderer( { preserveDrawingBuffer: true, alpha: true, powerPreference: 'high-performance'} );
		exp.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		exp.renderer.setSize( window.innerWidth, window.innerHeight );
		exp.renderer.toneMapping = THREE.ACESFilmicToneMapping;
		exp.renderer.toneMappingExposure = 1.2;
		exp.renderer.outputEncoding = THREE.sRGBEncoding;
		// console.log(exp.renderer.info);

		exp.container.appendChild( exp.renderer.domElement );

		const pmremGenerator = new THREE.PMREMGenerator( exp.renderer );
		pmremGenerator.compileEquirectangularShader();

		const controls = new OrbitControls( exp.camera, exp.renderer.domElement );
		controls.addEventListener( 'change', exp.render ); // use if there is no animation loop
		controls.update();

		if($(window).outerWidth() > 767){
			window.addEventListener( 'resize', exp.resize );
		}

		if (window.DeviceOrientationEvent && 'ontouchstart' in window) {
		    window.addEventListener("deviceorientation", exp.gyroMove)
		}else {
			document.addEventListener( 'mousemove', exp.onDocumentMouseMove )
		}
	},

	resize() {
		exp.camera.aspect = window.innerWidth / window.innerHeight;
		exp.camera.updateProjectionMatrix();

		exp.renderer.setSize( window.innerWidth, window.innerHeight );
		exp.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

		exp.render();
	},

	render() {
		exp.renderer.render( exp.scene, exp.camera );

		if (window.DeviceOrientationEvent && 'ontouchstart' in window) {
			if ( exp.model ) {
				exp.model.rotation.x = exp.mouseX;
				exp.model.rotation.y = exp.mouseY;
			}
		} else {
			let eventX = 0;
			let eventY = 0;
			const windowLimitW = $(window).outerWidth() * 20 / 100;
			const windowLimitH = $(window).outerHeight() * 20 / 100;

			if(exp.mouseX >= windowLimitW){
				eventX = windowLimitW
			} else if(exp.mouseX <= windowLimitW*-1){
				eventX = windowLimitW * -1
			} else {
				eventX = exp.mouseX
			}

			if(exp.mouseY >= windowLimitH){
				eventY = windowLimitH
			} else if(exp.mouseY <= windowLimitH*-1){
				eventY = windowLimitH * -1
			} else {
				eventY = exp.mouseY
			}

			if($('.dot.active').data('step') == 1){
				exp.targetX = eventX * .0004;
				exp.targetY = eventY * .0004;
			} else {
				exp.targetX = eventX * .0001;
				exp.targetY = eventY * .0001;
			}

			if ( exp.model ) {
				exp.model.rotation.y += 0.05 * ( exp.targetX - exp.model.rotation.y );
				exp.model.rotation.x += 0.05 * ( exp.targetY - exp.model.rotation.x );
			}
		}
	},

	onDocumentMouseMove( event ) {
		exp.mouseX = ( event.clientX - exp.windowHalfX );
		exp.mouseY = ( event.clientY - exp.windowHalfY );
	},

	gyroMove(event) {
		let x = event.beta;  // In degree in the range [-180,180)
  		let y = event.gamma; // In degree in the range [-90,90)

  		// Because we don't want to have the device upside down
		// We constrain the x value to the range [-90,90]
		// if (x >  90) { x =  90};
		// if (x < -90) { x = -90};
		// if (y >  90) { y =  90};
		// if (y < 0) { x = 0};

		x -= 45;

		if($('.dot.active').data('step') == 1){
			exp.targetX = x * .004
			exp.targetY = y * .004
		} else {
			exp.targetX = x * .0015
			exp.targetY = y * .0015
		}

		exp.mouseX = exp.targetX
		exp.mouseY = exp.targetY
	},

	cameraMove(step, callback) {

		positionArray.forEach(function (arrayItem) {
		    if(arrayItem.step == step){
		    	gsap.timeline({
		    		onComplete: () => {

							setTimeout(() => {
								exp.isAnim = 0

							}, 1000);

					}
		    	}).addLabel('start').to(exp.camera.position, {
		    		x: arrayItem.camera.position.x,
		    		y: arrayItem.camera.position.y,
		    		z: arrayItem.camera.position.z,
		    		duration: arrayItem.duration, ease: arrayItem.ease}, 'start')
				.to(exp.camera.rotation, {
					x: arrayItem.camera.rotation.x,
					y: arrayItem.camera.rotation.y,
					z: arrayItem.camera.rotation.z,
					duration: arrayItem.duration, ease: arrayItem.ease}, 'start')
		    }
		});

		if(callback && typeof callback == 'function') calback();
	},

	tick() {
		if(exp.stats) exp.stats.begin();

		// Render
		exp.render();

		if(exp.stats) exp.stats.end();

		exp.requestAnimId = window.requestAnimationFrame(exp.tick)
	},

	removeTick() {
		window.cancelAnimationFrame(exp.requestAnimId);
	},

	buildNavigation() {
		let dotHTML = '';
		positionArray.forEach(function (arrayItem) {
			const step = arrayItem.step
			const dotClass = arrayItem.step == 1 ? 'active' : ''
			dotHTML += `<div class="dot ${dotClass}" data-step="${step}" cursor-class="default"></div>`
			exp.totalDots++;
		});

		const navHTML = `
			<div class="arrow arrow-top" cursor-class="default"></div>
			${dotHTML}
			<div class="arrow arrow-bottom" cursor-class="default"></div>
		`;

		$('.visual-experience__navigation').append(navHTML);

		exp.initNavListeners()
	},

	initNavListeners() {
		$('.arrow-bottom').click(function() {
			if(exp.isAnim) return false;
			exp.isAnim = 1

			const current = $('.dot.active').data('step');
			const next  = (current >= exp.totalDots) ? 1 : current+1;
			const $next = $(`.dot[data-step="${next}"]`)

			$('.dot').removeClass('active');
			$next.addClass('active');

			if(current == 1){
				exp.hideUI(next)
			} else if(next == 1) {
				exp.cameraMove(next, exp.showUI(current))
			} else {
				exp.cameraMove(next, exp.toggleBigCircles(current, next))
			}
		})

		$('.arrow-top').click(function() {
			if(exp.isAnim) return false;
			exp.isAnim = 1

			const current = $('.dot.active').data('step');
			const next  = (current <= 1) ? exp.totalDots : current-1;
			const $next = $(`.dot[data-step="${next}"]`)
			$('.dot').removeClass('active');
			$next.addClass('active');

			if(current == 1){
				exp.hideUI(next)
			} else if(next == 1) {
				exp.cameraMove(next, exp.showUI(current))
			} else {
				exp.cameraMove(next, exp.toggleBigCircles(current, next))
			}
		})

		$('.dot').click(function() {
			if(exp.isAnim) return false;
			exp.isAnim = 1

			const current = $('.dot.active').data('step');
			const next  = $(this).data('step')
			const $next = $(`.dot[data-step="${next}"]`)

			$('.dot').removeClass('active');
			$next.addClass('active');

			if(current == 1){
				exp.hideUI(next)
			} else if(next == 1) {
				exp.cameraMove(next, exp.showUI(current))
			} else {
				exp.cameraMove(next, exp.toggleBigCircles(current, next))
			}
		})
	},

	hideUI(step) {

		const title = document.querySelectorAll('.visual-experience__content__title .word')
		const brush = document.querySelector('.visual-experience__content__brush path')
		const circles = document.querySelectorAll('.circles-small .circle')
		const marqueesL = document.querySelectorAll('.marquee__row[data-direction="left"]')
		const marqueesR = document.querySelectorAll('.marquee__row[data-direction="right"]')
		const currentCircle = $('.circles-focus div[data-step="'+step+'"]')

		// Big circles
		const bigCirclesWrap = $('.circles-focus')
		const allCircle = document.querySelectorAll('.circles-focus .circle')
		const allCircleSvg = document.querySelectorAll('.circles-focus .circle__svg path')
		const currentCircleSvg = $('.circles-focus div[data-step="'+step+'"] .circle__svg path')
		const currentCircleTitle= $('.circles-focus div[data-step="'+step+'"] .char')

		gsap.set(allCircleSvg, { zIndex: 0, drawSVG:"0%"});
		bigCirclesWrap.addClass('active')

		gsap.timeline({
			onComplete: function() {
				$(circles).addClass('inactive')
			}
		})
		.addLabel('start')
		.to(brush, {drawSVG:"0%"}, {duration: .6, drawSVG:"100%", ease:"power3.inOut"}, 'middle')
		.to(marqueesL, {xPercent: -30, opacity: 0, duration:.7, ease: 'Power4.out'}, 'start')
		.to(marqueesR, {xPercent: 30, opacity: 0, duration:.7, ease: 'Power4.out'}, 'start')
		.to(title, {yPercent: 50, opacity: 0, duration: .75, stagger: { from: "end", each: 0.2, ease: 'Power2.In' }, delay: .3}, 'start')
		.to(circles, {opacity: 0, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(allCircle, {opacity: 0, zIndex: 0, display: 'none', duration:1, ease: 'Power3.inOut'}, 'start')
		.addLabel('middle', "-=1")
		.call(exp.cameraMove, [step], 'middle')

		// Animate big circles
		.addLabel('circle-big-start')
		.to(currentCircle, {opacity: 1,  zIndex: 100, display: 'block', duration:.75, ease: 'Power3.inOut', delay: .5}, 'circle-big-start')
		.to(currentCircleSvg, {drawSVG:"100%", duration:1, ease: 'Power3.inOut', delay: .5}, 'circle-big-start')
		.to(currentCircleTitle, {y: 0, opacity: 1, duration: .6, delay: .5, stagger: { from: "left", each: 0.1, ease: 'Power3.inOut' }}, 'circle-big-start')
	},

	showUI(current) {

		const bigCirclesWrap = $('.circles-focus')
		const allCircle = document.querySelectorAll('.circles-focus .circle')
		const allCircleSvg = document.querySelectorAll('.circles-focus div[data-step="'+current+'"] .circle__svg path')
		const allCircleTitle = document.querySelectorAll('.circles-focus div[data-step="'+current+'"] .char')

		const title = document.querySelectorAll('.visual-experience__content__title .word')
		const brush = document.querySelector('.visual-experience__content__brush path')
		const circles = document.querySelectorAll('.circles-small .circle')
		const marqueesL = document.querySelectorAll('.marquee__row[data-direction="left"]')
		const marqueesR = document.querySelectorAll('.marquee__row[data-direction="right"]')

		bigCirclesWrap.removeClass('active')

		gsap.timeline({
			onStart: function() {
				$(circles).removeClass('inactive')
			}
		})
		.delay(.1)
		.addLabel('circle-big-start')
		.to(allCircleSvg, {drawSVG:"0%", duration:1, ease: 'Power3.inOut'}, 'start')
		.to(allCircleTitle, {y: 30, opacity: 0, duration: .4, stagger: { from: "end", each: 0.1, ease: 'Power3.inOut' }}, 'start')
		.to(allCircle, {opacity: 0, zIndex: 0, display: 'none', duration:1, ease: 'Power3.inOut'}, 'start')

		.addLabel('start')
		.to(circles, {opacity: 1, duration:.75, ease: 'Power3.inOut'}, 'start')
		.to(marqueesL, {xPercent: 30, opacity: 1, duration: 2, ease: 'expo.out'}, 'start')
		.to(marqueesR, {xPercent: -30, opacity: 1, duration: 2, ease: 'expo.out'}, 'start')
		.to(title, {yPercent: 0, opacity: 1, duration: 1, stagger: { from: "start", each: 0.2, ease: 'Power2.Out' }, delay: .6 }, 'start')
		.addLabel('middle', "-=1")
		.to(brush, {duration: 1, drawSVG:"100%", ease:"power3.inOut"}, 'middle')
	},

	toggleBigCircles(current, next) {
		const allCircle = document.querySelectorAll('.circles-focus .circle')
		const allCircleSvg = document.querySelectorAll('.circles-focus div[data-step="'+current+'"] .circle__svg path')
		const allCircleTitle = document.querySelectorAll('.circles-focus div[data-step="'+current+'"] .char')

		const currentCircle = $('.circles-focus div[data-step="'+next+'"]')
		const currentCircleSvg = $('.circles-focus div[data-step="'+next+'"] .circle__svg path')
		const currentCircleTitle= $('.circles-focus div[data-step="'+next+'"] .char')

		gsap.timeline({
			onComplete: function() {
				// $(circles).addClass('inactive')
			}
		})
		.addLabel('start')
		.to(allCircleSvg, {drawSVG:"0%", duration:1, ease: 'Power3.inOut'}, 'start')
		.to(allCircleTitle, {y: 30, opacity: 0, duration: .4, stagger: { from: "end", each: 0.1, ease: 'Power3.inOut' }}, 'start')
		.to(allCircle, {opacity: 0, zIndex: 0, display: 'none', duration:1, ease: 'Power3.inOut'}, 'start')

		.addLabel('circle-big-start')
		.to(currentCircle, {opacity: 1, zIndex: 100, display: 'block', duration:.75, ease: 'Power3.inOut', delay: .5}, 'circle-big-start')
		.to(currentCircleSvg, {drawSVG:"100%", duration:1, ease: 'Power3.inOut', delay: .5}, 'circle-big-start')
		.to(currentCircleTitle, {y: 0, opacity: 1, duration: .6, delay: .5, stagger: { from: "left", each: 0.1, ease: 'Power3.inOut' }}, 'circle-big-start')

	},

	initCircleText() {
		$(document).on('mouseenter', '.circles-small .circle__wrap', function(e){
			const chars = $(this).parent().find('.char');
			gsap.killTweensOf(chars)

		 	gsap
		 		.to(chars, {y: 0, opacity: 1, duration: .4, stagger: { from: "start", each: 0.1, ease: 'Power2.Out' }})
		})
		$(document).on('mouseleave', '.circles-small .circle__wrap', function(){
			const chars = $(this).parent().find('.char');
			gsap.killTweensOf(chars)

		 	gsap
		 		.to(chars, {y: 30, opacity: 0, duration: .3, stagger: { from: "end", each: 0.1, ease: 'Power2.Out' }})
		})

		$('.circle__wrap').click(function(){
			if(exp.isAnim) return false;
			exp.isAnim = 1

			const current = $('.dot.active').data('step');
			const step = $(this).data('step');
			const $next = $(`.dot[data-step="${step}"]`)

			$('.dot').removeClass('active');
			$next.addClass('active');

			exp.hideUI(step)
		})
	}
}

export default exp;
