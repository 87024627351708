
var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
var scroll = require('./locomotive');

const isotopeFilter = {

  // TRUE after first filtering
  hasClicked: false,

  initLoad() {

    // make isotope a Jquey plugin
    jQueryBridget('isotope', Isotope, $);

    if ($('#js-isotope-filter').length == 0) return

    isotopeFilter.mobileInit();
    isotopeFilter.filterInit();


  },
  getCategoryByUrl(){

    // when, on single product page, clickong on the line (category) name, you
    // go back to products page. To filter the elements
    const PAGEURL = window.location.href
    if (PAGEURL.indexOf("?cat=") >= 0){
      return PAGEURL.split('?cat=')[1]
    }
  },
  filterInit(index = 0) {

    // filter all default
    let filterEl = $('#js-isotope-filter .filter-element[data-filter=all]')

    var searchedCatName = isotopeFilter.getCategoryByUrl();

    if (searchedCatName) filterEl = $('#js-isotope-filter .filter-element[data-filter='+searchedCatName+']')

    isotopeFilter.filterColor(filterEl)

    isotopeFilter.filterClick( filterEl.data('filter') ) ;

    // click
    $('#js-isotope-filter .filter-element').on('click',function(){

        const selectedCategory = $(this).data('filter');

        isotopeFilter.filterColor($(this))

        isotopeFilter.filterClick( selectedCategory )

        // ! desktop animations
        if ( $(window).outerWidth() < 991) return

        scroll.goTo(document.querySelector('#prods-cat-inner-wrapper'))

      }

    )

  },
  filterClick(newVal = null) {

    let elToIsotope

    // we use isotope filters in products and news pages
    // ... just wanna know where we are

    // products
    if ( $('.prods-cat-wrapper #prods-cat-inner-wrapper').length > 0){
      elToIsotope = $('.prods-cat-wrapper #prods-cat-inner-wrapper').eq(0)
    }
    // news
    else if ( $('.news-cat-wrapper #news-cat-inner-wrapper').length > 0){
      elToIsotope = $('.news-cat-wrapper #news-cat-inner-wrapper').eq(0)
    }

    elToIsotope.isotope({
      itemSelector: '.el-cat',
      filter: '.el-cat--' + newVal
    });

    // UPDATE SCROLL POSITION & MOVE DOWN
    scroll.update()

    // ! desktop animations
    if ( $(window).outerWidth() < 991) return

    // animate only after the first filtering
    if ( isotopeFilter.hasClicked ){

      const SPEED = 300
      const filteredCat = $('.el-cat--' + newVal);
      const filteredEls = filteredCat.find('.products-wrapper__product');
          filteredEls.hide();
          filteredEls.each(function(index){
            let product = $(this)
            product.delay( index * SPEED ).fadeIn()
          })
    }

    isotopeFilter.hasClicked = true

  },
  filterColor(filterEl){

      // add active and remove from others
      $('#js-isotope-filter .filter-element').removeClass('active'), filterEl.addClass('active')
  },
  mobileInit(){

    // no desktop
    if ( $(window).outerWidth() > 991) return

    const firstOption = $('#js-isotope-filter .filters-wrapper__mobile .options span:first-child')
    const selectedOption = $('#js-isotope-filter .filters-wrapper__mobile .selected-option')
    const options = $('#js-isotope-filter .filters-wrapper__mobile .options')

    let searchedCatName = isotopeFilter.getCategoryByUrl();
    let choosenOptionEl = firstOption
    if (searchedCatName)  choosenOptionEl = options.find('[data-filter='+searchedCatName+']')

    isotopeFilter.updateMobileSelect( choosenOptionEl )

    isotopeFilter.filterClick(choosenOptionEl.data('filter'))

    selectedOption.on('click',function(){

      // show / hide options
      selectedOption.parent('.filter-group__inner').toggleClass('opened')

    })
    options.find('span').on('click',function(){

      isotopeFilter.updateMobileSelect( $(this) )

      selectedOption.parent('.filter-group__inner').toggleClass('opened')

      isotopeFilter.filterClick( $(this).data('filter'))

    })
  },

  updateMobileSelect(chooseOptionEl){

      const selectedOption = $('#js-isotope-filter .filters-wrapper__mobile .selected-option')

      // show / hide options
      selectedOption.find('span').remove()

      chooseOptionEl.clone().appendTo(selectedOption);

  }
}

export default isotopeFilter;
