export const positionArray = [
	{
		step: 1,
		camera: {
			position: {
				x: 0,
				y: 0,
				z: 95
			},
			rotation: {
				x: 0,
				y: 0,
				z: 0
			}
		},
		duration: 1.8,
		ease: "Power4.inOut"
	},
	{
		step: 2,
		camera: {
			position: {
				x: -35.88,
				y: 39.01,
				z: 42.32
			},
			rotation: {
				x: -0.73,
				y: -0.7,
				z: -0.52
			}
		},
		duration: 1.8,
		ease: "Power4.inOut"
	},
	{
		step: 3,
		camera: {
			position: {
				x: 1.4,
				y: 18.94,
				z: 33.2
			},
			rotation: {
				x: -0.32,
				y: 0.17,
				z: -0.32
			}
		},
		duration: 1.8,
		ease: "Power4.inOut"
	},
	{
		step: 4,
		camera: {
			position: {
				x: -17.9,
				y: -0.35,
				z: 33
			},
			rotation: {
				x: -0.32,
				y: -0.14,
				z: -0.21
			}
		},
		duration: 1.8,
		ease: "Power4.inOut"
	},
	{
		step: 5,
		camera: {
			position: {
				x: -11.46,
				y: -24.15,
				z: 37.65
			},
			rotation: {
				x: 0.59,
				y: -0.22,
				z: -0.15
			}
		},
		duration: 1.8,
		ease: "Power4.inOut"
	},
]
