import { gsap } from 'gsap';

const bottomNav = {
	initReady() {
		this.initParallax();
	},

	initParallax() {
		const $el = $(".bottom-navigation")
		const $gallery = $('.galleryImg img')

		$el.mousemove(function(e) {
		  	bottomNav.parallaxIt(e, $el, $gallery, -50);
		});
	},

	parallaxIt(e, container, target, movement) {
		var $this = container
		var relX = e.pageX - $this.offset().left;
		var relY = e.pageY - $this.offset().top;

		gsap.to(target, {
			x: (relX - $this.width() / 2) / $this.width() * movement,
			y: (relY - $this.height() / 2) / $this.height() * movement,
			duration: 1
		});
	},
}

export default bottomNav;
