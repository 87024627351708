import { gsap } from 'gsap';

const top = {
	initLoad() {
		this.initTop();
	},

	initTop() {
		const $el = $('.top__content');
		if($el.length) {
			const $word = $el.find('.word');
			
			gsap
				.to($word, {y: 0, opacity: 1, duration: 1, stagger: { from: "start", each: 0.2, ease: 'Power2.inOut' }, delay: 1.8 })	
		}
	},
}

export default top;