import gsap from "gsap";

const news = {
	windowHalfX: window.innerWidth,
	windowHalfY: window.innerHeight,
	mouseX: 0,
	mouseY: 0,
	currentImg: '',

	initLoad() {
		document.addEventListener( 'mousemove', news.imageFollow );
		this.productsListAnimations();
	},

  	productsListAnimations() {
  		const $el = $('.news-list');
		if($el.length == 0) return false

  		$(document).on('mouseenter', '.news__row', function(){
			const $newsLine = $(this).find('.news__row__wrap--clipped')
			const $newsImgWrap = $(this).find('.news__img')
			const $newsImg = $(this).find('img')
			news.currentImg = $newsImgWrap

			gsap.killTweensOf($newsLine)
			gsap.killTweensOf($newsImgWrap)
			gsap.killTweensOf($newsImg)

  			news.loopRender();
  			$(this).addClass('high');

		 	gsap.timeline()
		 		.addLabel('start')
		 		.to($newsLine, {"clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: 1.2, ease: "Power3.in" }, 'start')
		 		.to($newsImgWrap, {opacity: 1, duration: .3, ease: "Expo.out"}, 'start')
		 		.to($newsImgWrap, {"clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: .8, ease: "Expo.out", delay: .1 }, 'start')
		 		.to($newsImg, {scale: 1.1, duration: 2, ease: "power1.out"}, 'start')
		})

		$(document).on('mouseleave', '.news__row', function(){
			const $newsLine = $(this).find('.news__row__wrap--clipped')
			const $newsImgWrap = $(this).find('.news__img')
			const $newsImg = $(this).find('img')
			news.currentImg = $newsImgWrap

			gsap.killTweensOf($newsLine)
			gsap.killTweensOf($newsImgWrap)
			gsap.killTweensOf($newsImg)

			news.stopRendering();
			$(this).removeClass('high');

		 	gsap.timeline()
		 		.addLabel('start')
		 		.to($newsLine, {"clip-path": "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", duration: .8, ease: "Power3.out" }, 'start')
				.to($newsImg, {scale: 1.2, duration: .3, ease: "Expo.easeOut" }, 'start')
		 		.to($newsImgWrap, {opacity: 0, "clip-path": "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", duration: .3, ease: "Expo.easeOut" }, 'start')
		})

		$(document).on('mousemove', '.news__row', function(){
			const $container = $(this);
			const $newsImgWrap = $(this).find('.news__img')
			const $newsImg = $(this).find('img')

			news.parallax($container, $newsImg, -100)
		})
  	},

  	imageFollow(event) {
  		news.mouseX = ( event.clientX );
		news.mouseY = ( event.clientY );
  	},

  	loopRender() {
        if ( !news.requestId ) {
            news.requestId = requestAnimationFrame(() => news.render());
        }
    },

    stopRendering() {
        if ( news.requestId ) {
            window.cancelAnimationFrame(news.requestId);
            news.requestId = undefined;
        }
    },

    render() {
    	news.requestId = undefined;
    	const parentOffset = news.currentImg.parent().offset(); 

        gsap.set(news.currentImg, {
            left: Math.abs(news.mouseX - parentOffset.left + 1),
            top: Math.abs(news.mouseY - parentOffset.top),
            duration: 1,
            ease: "Power4.easeOut"
        });

        news.loopRender();
    },

    parallax(container, target, movement) {
		var $container = container;
		var relX = news.mouseX - $container.offset().left;
		var relY = news.mouseY - $container.offset().top;

		gsap.to(target, 1, {
			x: ((relX - $container.width() / 2) / $container.width() * movement) * -1,
			y: ((relY - $container.height() / 2) / $container.height() * movement) * -1,
			duration: 1,
			ease: "Power4.easeOut"
		});
	}

}

export default news;
