import gsap from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin);

import scroll from './locomotive';
import splitting from './splitting';

const scrActions = {
	initLoad() {
		this.changeLogo();
		this.setSvg()
		this.splitLines();
	},

	detectAnim(args) {
		scrActions.titleAnim(args);
		scrActions.pageTopAnim(args);
		scrActions.flBlocksAim(args);
		scrActions.bottomNavAnim(args);
	},

	setSvg() {
		const svgFill = document.querySelectorAll('.svg-fill path')
		gsap.set(svgFill, { drawSVG: "0%" });
	},

	splitLines() {
		const splitEl = $('.split-lines')
		splitEl.each(function (i, el) {
			splitting.splitText(el);
		})
	},

	changeLogo() {
		const $header = $('.header')
		const $logo = $('.header__logo')
		const $pageIntro = $('.page-intro')

		if (scroll.loc) {
			const position = scroll.loc.on("scroll", args => {
				if (args.scroll.y > 100) $logo.addClass('sticky')
				else $logo.removeClass('sticky')

				scrActions.detectAnim(args);
			});
		}

		if ($pageIntro.length && scroll.loc) {
			const diff = $(window).outerHeight() - $('.header__logo__small').outerHeight();
			const position = scroll.loc.on("scroll", args => {
				if (args.scroll.y > diff) $header.addClass('red')
				else $header.removeClass('red')
			});
		}
	},
	titleAnim(args) {
		const $title = $('.generic-title--hover .word')


		if ($title.length) {
			Object.keys(args.currentElements).forEach(function (item) {
				const current = args.currentElements[item];

				if (current.el.classList.contains('generic-title__wrap')) {
					const $el = $(current.el)
					const $elChars = $el.find('.generic-title--hover .word')
					const $titleSvg = $el.find('.generic-title--hover .hand-arrow')
					const delay = $el.find('.generic-title--hover .hand-arrow').data('delay') || ''


					if (!$el.hasClass('triggered')) {
						$el.addClass('triggered');

						const tl = gsap.timeline()
							.addLabel('start')
							.to($elChars, { opacity: 1, duration: 1, stagger: { from: "start", each: 0.15, ease: 'none' } }, 'start')

						if ($titleSvg)
							tl.addLabel('svg')
						tl.to($titleSvg, { "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: .7, ease: "Power4.inOut" }, `svg${delay}`)
					}
				}
			});
		}
	},

	pageTopAnim(args) {
		const $top = $('.section--top')

		if ($top.length) {
			Object.keys(args.currentElements).forEach(function (item) {
				const current = args.currentElements[item];

				if (current.el.classList.contains('section--top')) {
					const $el = $(current.el)
					const $bg = $el.find('.top__bg')
					const scale = Math.abs(1 + (args.scroll.y * 0.0002))

					gsap
						.to($bg, { scale, duration: .2 })
				}
			})
		}
	},

	flBlocksAim(args) {
		const $blockRow = $('.fl-block')

		if ($blockRow.length) {
			Object.keys(args.currentElements).forEach(function (item) {
				const current = args.currentElements[item];

				if (current.el.classList.contains('fl-block')) {
					const $el = $(current.el)
					const $imgWrap = $el.find('.fl-block__imgWrap')
					const $imgBlock = $el.find('.fl-block__imgWrap img');
					const $svgFill = $el.find('.svg-fill path')
					const $titleWords = $el.find('.fl-block__title .word')
					const $text = $el.find('.fl-block__text .line')
					const $bigTitle = $el.find('.fl-block__bigTitle .word')

					if (!$el.hasClass('triggered')) {
						$el.addClass('triggered');

						const tl = gsap.timeline()
							.addLabel('start')
						if ($imgWrap.length)
							tl.to($imgWrap, { "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: 1.8, ease: "expo.out", delay: .1 }, 'start')

						if ($imgBlock.length)
							tl.to($imgBlock, { scale: 1, duration: 1.6, ease: "power1.out" }, 'start-=0.3')

						if ($svgFill.length)
							tl.to($svgFill, { drawSVG: "100%", duration: .8, ease: "none", delay: 1 }, 'start')

						if ($bigTitle.length)
							tl.to($bigTitle, { y: 0, duration: 1.5, stagger: { from: "start", each: 0.15, ease: 'Power2.inOut' } }, 'start')

								.addLabel('middle')

						if ($titleWords.length)
							tl.to($titleWords, { y: 0, opacity: 1, duration: 1, stagger: { from: "start", each: 0.2, ease: 'Power2.inOut' } }, 'middle-=.5')

						if ($text.length)
							tl.to($text, { opacity: 1, y: 0, duration: .6, stagger: { from: "start", each: 0.2, ease: 'none' } }, 'middle')
					}

				}
			})
		}
	},

	bottomNavAnim(args) {
		const $blockRow = $('.bottom-navigation')

		if ($blockRow.length) {
			Object.keys(args.currentElements).forEach(function (item) {
				const current = args.currentElements[item];

				if (current.el.classList.contains('galleryImg')) {
					const $el = $(current.el)
					const $elImg = $el.find('img')

					if (!$el.hasClass('triggered')) {
						$el.addClass('triggered');

						const tl = gsap.timeline()
							.addLabel('start')
							.to($el, { "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: 1.8, ease: "expo.out", delay: .1 }, 'start')
							.to($elImg, { scale: 1.1, duration: 1.6, ease: "power1.out" }, 'start')
					}
				}

				if (current.el.classList.contains('bottom-navigation__content')) {
					const $el = $(current.el)
					const $subtitle = $el.find('.bottom-navigation__subtitle')
					const $title = $el.find('.bottom-navigation__title .word')
					const $button = $el.find('.button')

					if (!$el.hasClass('triggered')) {
						$el.addClass('triggered');

						const tl = gsap.timeline()
							.addLabel('start')
							.to($subtitle, { opacity: 1, y: 0, duration: .5, ease: "Power2.inOut" }, 'start')
							.to($title, { y: 0, opacity: 1, duration: .6, stagger: { from: "start", each: 0.2, ease: 'Power2.inOut' }, delay: .5 }, 'start')
							.to($button, { opacity: 1, duration: .5, ease: "Power2.inOut", delay: 1.5 }, 'start')
					}
				}
			})
		}
	}

}

export default scrActions;
