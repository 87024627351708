import gsap from "gsap";

const menu = {
	isAnim: 0,

	initReady() {
		this.menuInit();
		this.menuAnimations();
		this.logoColor();
	},

	menuInit() {
		const $menu = $('.menu')
		const $menuBg = $('.menu__bg')
		const menuLink = document.querySelectorAll('#menu-header .li-wrap');
		const $menusocial = $('.header__social ul');
		const bottomMenu = document.querySelectorAll('.header__footer ul');

		const tl = gsap
			.timeline({
				paused: true,
				onComplete: () => {
					menu.isAnim = 0;
				},
				onReverseComplete: () => {
					menu.isAnim = 0;
				}
			})
			.addLabel('start')
			.to($menuBg, { scale: 1, duration: 1.2, ease: "power4.out" }, 'start')
			.addLabel('middle', "-=0.5")
			.to(menuLink, { y: 0, opacity: 1, duration: .8, stagger: { from: "start", each: 0.1, ease: 'Power2.Out' } }, 'middle')
			.addLabel('end', "-=0.4")
			.to($menusocial, { y: 0, opacity: 1, duration: .6, ease: "power4.inOut" }, 'end')
			.to(bottomMenu, { y: 0, opacity: 1, duration: .6, ease: "power4.inOut", delay: .3 }, 'end')
		tl.reversed(true)

		$('.header__hamburger').on('click', function () {
			if (menu.isAnim != 0) return false
			menu.isAnim = 1

			$('.header__hamburger, .header').toggleClass('opened');

			if (tl.reversed()) {
				$menu.removeClass('closed')
				tl.timeScale(1).play();
			} else {
				$menu.addClass('closed')
				tl.timeScale(1.5).reverse();
			}
		})
	},

	menuAnimations() {
		$(document).on('mouseenter', '#menu-header li', function () {
			const index = $(this).data('item');
			const clippedLink = $(this).find('.clip-link')
			const menuImgWrap = $(`.menu__images__wrap[data-item="${index}"]`)
			const menuImg = $(`.menu__images__wrap[data-item="${index}"] .menu__images__image`)

			gsap.killTweensOf(menuImgWrap)
			gsap.killTweensOf(menuImg)

			menuImgWrap.addClass('high');

			gsap.timeline()
				.addLabel('start')
				.to(clippedLink, { "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: .35, ease: "expo.out" }, 'start')
				.to(menuImgWrap, { "clip-path": "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", duration: 1.8, ease: "expo.out", delay: .1 }, 'start')
				.to(menuImg, { scale: 1, duration: 1.6, ease: "power1.out" }, 'start-=0.3')
		})
		$(document).on('mouseleave', '#menu-header li', function () {
			const index = $(this).data('item');
			const clippedLink = $(this).find('.clip-link')
			const menuImgWrap = $(`.menu__images__wrap[data-item="${index}"]`)
			const menuImg = $(`.menu__images__wrap[data-item="${index}"] .menu__images__image`)

			gsap.killTweensOf(menuImgWrap)
			gsap.killTweensOf(menuImg)

			menuImgWrap.removeClass('high');

			gsap.timeline()
				.addLabel('start')
				.to(menuImg, { scale: 1.2, duration: 1.6, ease: "power1.out" }, 'start')
				.to(menuImgWrap, { "clip-path": "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", duration: 1.2, ease: "expo.out" }, 'start')
				.to(clippedLink, { "clip-path": "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", duration: .35, ease: "expo.out" }, '-=1.5')
		})
	},

	logoColor() {

		//page where logo must be colored
		const els = ['#prods-cat-inner-wrapper', '#single-product', '#our-world', '#news-content', '#contacts'];
		els.forEach(el => {
			if ($(el).length > 0) {
				$('.header__logo__default svg, .header__hamburger, .header__logo__small svg').addClass('colored')
			}
		});

	}

}

export default menu;
