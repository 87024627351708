import smoothState from 'smoothState';
import scroll from './locomotive';
import exp from './visual-experience';
import cursor from './cursor';

const pageTransition = {
	initReady() {
		this.initPageTransition();
	},

	initPageTransition() {
		const options = {
			anchors: 'a',
			forms: 'form',
			allowFormCaching: false,
			scroll: true,
		    blacklist: '.no-smoothState , .no-smoothState > a, .wpcf7-form',
		    //prefetch: true,
	    	cacheLength: 0,
		    onBefore: function($currentTarget, $container) {
		    	if($('.visual-experience').length) exp.removeTick()
		    },
		    onStart: {
		    	duration: 400,
		    	render: function ($container) {
		    		$('#cursor').attr('class', 'active loading')

		    		if ($('.header__hamburger').hasClass('opened')) {
		    			$('.header__hamburger, .header, .header__menu').removeClass('opened');
				        setTimeout(function(){
			        		$('#main').addClass('is-exiting');
				        }, 200)
		    		} else {
			        	$('#main').addClass('is-exiting');
		    		}

		    		setTimeout(() => {
			    		scroll.goTo(0,{
				        	duration: 0
				        });
		    		}, 350)
		        }
		    },
		    onReady: {
		    	render: function ($container, $newContent) {
			        $container.html($newContent)

							// we have to detect new images load
							// and update scroll in order to
							// avoid elements and images disappearing
    					scroll.imagesLoadUpdate();
			    }
		    },
		    onAfter: function($container, $newContent){
					// update body class
					$('body').attr('class', $('#bodydata').attr('class'))

		    	$('#cursor').removeClass('loading')

		        $(document).trigger('rebuild');

						var cf_selector = 'div.wpcf7 > form';
						var cf_forms = $newContent.find(cf_selector);
						if (cf_forms.length>0) {
							$(cf_selector).each(function() {
								var $form = $(this);
								wpcf7.init( $form[0] );
							});
						}

						// remove cursor texts
						//  if found in previous page
						cursor.cleanCursorText()

		        setTimeout(function(){
		        	if($newContent.find('.visual-experience').length <= 0){
		       			$('#main').removeClass('is-exiting');
		        	}
		        }, 150);

		    }
		};

		$('#main').smoothState(options).data('smoothState');
  	}

}

export default pageTransition;
