import marquee from 'jquery.marquee';

const marqueeText = {
	initLoad() {
		this.initMarquee();
	},

	initMarquee() {
		const $el = $('.marquee');
		if($el.length) {
			const els = $el.find('.marquee__row');

			els.each(function(i, el){
				const direction = $(el).data('direction') || 'left';

				$(el).marquee({
					duration: 240 * 1000,
					gap: 0,
					delayBeforeStart: 0,
					direction,
					duplicated: true,
					startVisible: true
				});
			})
		}
	},
}

export default marqueeText;