import $ from 'jquery'
import '../sass/main.scss';
import utils from './utils/utils';
import preloader from './preloader';
import scroll from './locomotive';
import menu from './menu';
import lazyLoad from './lazyLoad';
import smoothState from './smoothState';
import video from './video';
import splitting from './splitting';
import animations from './animations';
import exp from './visual-experience';
import marqueeText from './marquee';
import cursor from './cursor';
import scrActions from './scroll-actions';
import products from './products';
import news from './news';
import top from './top';
import bottomNav from './bottom-navigation';
import isotopeFilter from './isotope';
import contacts from './contacts';

window.$ = require('jquery');
window.jQuery = require('jquery');

const siteGlobal = {
  initReady() {
    scroll.initReady();
    utils.initReady();
    preloader.initReady();
    smoothState.initReady();
    menu.initReady();
    if($('.visual-experience').length){
      $('.visual-experience').height($(window).outerHeight());
      exp.initReady();
    }
    if(!utils.is_touch_device()) cursor.initReady()
    else cursor.destroy();

    bottomNav.initReady();
  },
  initLoad() {
    scroll.update();
    scroll.goToTop();
  	lazyLoad.initLoad();
    video.initLoad();
    splitting.initLoad();
    animations.initLoad();
    marqueeText.initLoad();
    scrActions.initLoad();
    products.initLoad();
    news.initLoad();
    top.initLoad();
    isotopeFilter.initLoad();
    contacts.initLoad();
    menu.logoColor();
  },
  initScroll( ){
  },
  initResize() {
    if($(window).outerWidth() > 767){
      if($('.visual-experience').length) $('.visual-experience').height($(window).outerHeight());
    }
  },
  rebuildAllEvents() {
    utils.initReady();
    menu.initReady();
    bottomNav.initReady();

    if($('.visual-experience').length){
      $('.visual-experience').height($(window).outerHeight());
      exp.initReady();
    }

    siteGlobal.initLoad();
  },
};

$(document).ready(() => {
  siteGlobal.initReady();
});

$(document).on('rebuild', () => {
  siteGlobal.rebuildAllEvents();
});

$(window).on('load', () => {
  if($('.page-template-page_HOME').length <= 0) preloader.update(1, true);
  siteGlobal.initLoad();
});

$(window).on('resize', () => {
  siteGlobal.initResize();
});

$(window).on('scroll', () => {
  siteGlobal.initScroll();
});
